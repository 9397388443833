import { DavosEnvironment } from "../app/shared/models/interface";

const local: DavosEnvironment = {
  is_local: true,
  production: false,
  api_url: "https://dev-api.heatingoilfinder.com/",
  seo_api_url: 'https://dev-api.heatfleet.com/api/',
  seo_api_key: 'oiwe43raiasdl4kha6sdf123',
};

const dev: DavosEnvironment = {
  is_local: false,
  production: false,
  api_url: "https://dev-api.heatingoilfinder.com/",
  seo_api_url: 'https://dev-api.heatfleet.com/api/',
  seo_api_key: 'oiwe43raiasdl4kha6sdf123',
};

const uat: DavosEnvironment = {
  is_local: false,
  production: false,
  api_url: "https://demo-api.heatingoilfinder.com/",
  seo_api_url: 'https://demo-api.heatfleet.com/api/',
  seo_api_key: 'oiwe43raiasdl4kha6sdf123',
};

const prod: DavosEnvironment = {
  is_local: false,
  production: true,
  api_url: "https://api.heatingoilfinder.com/",
  seo_api_url: 'https://api.heatfleet.com/api/',
  seo_api_key: 'oiwe43raiasdl4kha6sdf123',
};

export const environment = dev;