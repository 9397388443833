import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AppService } from '@monorepo/utils';

@Component({
  standalone: true,
  imports: [RouterModule, HttpClientModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

  constructor(
    private meta: Meta,
    public appService: AppService,
  ) { }

  ngOnInit() {
    this.appService.setPlatform(environment.is_local);
    if (environment.production) {
      this.meta.updateTag({
        name: 'robots',
        content: 'all',
      });
    } else {
      this.meta.updateTag({
        name: 'robots',
        content: 'noindex, nofollow',
      });
    }
  }
}
