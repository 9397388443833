import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [ 
    provideRouter(appRoutes),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideClientHydration(withHttpTransferCacheOptions({ includePostRequests: true, }))
  ],
};
